/***
*
*   PROFILE
*   Update the user profile or close the account
*
**********/

import React, { Fragment, useContext, useState, useEffect } from 'react';
import { AuthContext, ViewContext, Form, Card,
  AccountNav, Button, useNavigate, Animate, useAPI, Event, Message, Icon } from 'components/lib';
import {ConnectButton, useWallet, addressEllipsis, useWallets} from '@suiet/wallet-kit';
import Axios from "axios";
import {CopyToClipboard} from 'react-copy-to-clipboard';

export function WalletsProfile(props){

  const navigate = useNavigate();
  
  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const suiWallet = useWallet();

  // fetch
  const user = useAPI('/api/user');
  const userWallets = useAPI('/api/user/wallets');
  const [enabled, setEnabled] = useState(undefined);
  const [populatedWallets, setPopulatedWallets] = useState([]);

  // Replace the refresh function with a page reload
  const refreshWallets = () => {
    setTimeout(() => {
      window.location.reload();
    }, 2000); // Keep the same delay before reload
  };

  useEffect(() => {
    if(suiWallet.account){
      //console.log('suiWallet', suiWallet);
      Axios.post("/api/user/web3", {
        wallet_address: suiWallet.address,
        wallet_type: "primary",
        chain: "SUI",
      }).then((res) => {
        console.log(res);
        if (res.data) {
          if (res.data.success) {
            if(res.data.message){
              if(res.data.message == 'Wallet updated'){
                
              } else {
                viewContext.notification.show(res.data.message, "success", true);
                refreshWallets();
              }
              
            }
            //refreshWallets(); // Refresh after successful API call
          } else {
            res.data.message &&
              viewContext.notification.show(res.data.message, "error", true);
          }
        }
      }).catch(error => {
        console.error('Error connecting wallet:', error);
        viewContext.notification.show("Failed to connect wallet", "error", true);
      });
    }
  }, [suiWallet.account]);

  

  useEffect(() => {

    if(user.data && userWallets.data){
      
      let populatedWalletsTemp = [];
      //console.log(userWallets.data);

      userWallets.data.forEach((userWallet, index) => {
        populatedWalletsTemp.push({wallet_address: userWallet.wallet_address, chain: userWallet.chain, wallet_type: userWallet.wallet_type});
      });

      setPopulatedWallets(populatedWalletsTemp);
    }
  }, [user.data, userWallets.data])

return (
  <Fragment>
    <Message
        messageID="Message_Scribe_Wallets"
        title="Scribe Wallets"
        text="Here are the wallets you have connected to your Scribe account. If you connect your SUI Wallet, you will be able to use some of your Scribe NFTs in the SoulSeed platform."
        //buttonText="Wallets"
        //buttonLink="/account/wallets"
        type="info"
        noIcon
      />
      <AccountNav />
      <Animate>
        <Card dark
          title='Connect Wallet'
          subtitle='Your Scribe NFTs could be used in the SoulSeed platform if you connect your SUI Wallet. Use the connect button to set your primary SUI wallet.'
          loading={ user.loading }>
            <>
              <ConnectButton 
                label='Connect SUI Wallet' 
                onConnectSuccess={() => {
                  console.log("Connected SUI Wallet");
                }}
              />
              
              {suiWallet?.account && (
                <div>
                  <CopyToClipboard
                    options={{ debug: true, message: "Yeah" }}
                    text={suiWallet.address}
                    onCopy={() => {
                      //console.log("Copied");
                      //setCopied(true);
                    }}
                  >
                    <span>{suiWallet.address}&nbsp;<button ><Icon color="dark" image='copy' size={ 12 } /></button></span>
                  </CopyToClipboard>
                </div>
              )}
            </>
        </Card>
      
        <Card
          dark
          title='Wallets'
          loading={ userWallets.loading }>


          { user?.data && populatedWallets.length > 0 && 
            <div>
              {populatedWallets.map((wallet, index) => {
                let chain = wallet.chain ? wallet.chain.toUpperCase() : 'ETH';
                //show the chain name in colors. Use orange for ETH, blue for SUI, purple for SOL, and purple for NEAR
                let chainColor = chain === 'ETH' ? 'orange' : chain === 'SUI' ? 'blue' : chain === 'SOL' ? 'purple' : 'purple';
                let chainSpan = <span style={{color: chainColor}}>{chain}</span>;

                return <div key={wallet.wallet_address || index}> {chainSpan}: {wallet.wallet_address} {wallet.wallet_type ? (<span style={{color: 'green'}}>Primary</span>) : ''}</div>
              })}
            </div>
          }
        </Card>
      </Animate>
    </Fragment>
  );
}
