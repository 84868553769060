import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/app';

import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';

import {WalletProvider} from '@suiet/wallet-kit';
import '@suiet/wallet-kit/style.css';

import ErrorBoundary from './components/ErrorBoundary';

// Add compatibility layer for findDOMNode
const ReactDOM16 = require('react-dom');
if (!ReactDOM16.findDOMNode) {
  ReactDOM16.findDOMNode = (component) => {
    return component?.nodeType === 1 
      ? component 
      : ReactDOM.findDOMNode(component);
  };
}

//https://www.linumlabs.com/articles/how-to-use-web3react-in-your-next-project

function getLibrary(provider) {
  const library = new Web3Provider(provider)
  library.pollingInterval = 15000;
  return library;

  //return new ethers(provider)
}

// Use React 17 render method
ReactDOM.render(
  <ErrorBoundary>
    <Web3ReactProvider getLibrary={getLibrary}>
      <WalletProvider>
        <App />
      </WalletProvider>
    </Web3ReactProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);

const welcome = () => console.log('Welcome to NFTScribe Client 🚀')
welcome('de529c70-eb80-4dfb-9540-5075db7545bf')