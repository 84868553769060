/***
 *
 *   DASHBOARD
 *   Template dashboard example demonstrating various components inside a view.
 *
 **********/

import React, { useEffect, useState, useContext, Fragment } from "react";


import {
  Card,  ViewContext, AuthContext, Stat,  ProgressBar,  Chart,  Image,  Button,  Row,  Link,  SocialShare,  DashboardNav, 
  Message,  Grid,  Animate,  Feedback,  useAPI,  useNavigate, Icon,
} from "components/lib";

import { useWeb3React } from "@web3-react/core";

import Axios from "axios";
import Style from "./shop.tailwind.js";

export function ShopDashboard(props) {
  
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const navigate = useNavigate();
  const user = useAPI("/api/user", authContext);

  const [shopList, setShopList] = useState(null);
  const [referralURL, setReferralURL] = useState(null);
  const [advanced, setAdvanced] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  let shop_referrer = urlParams.get('s');
  if(shop_referrer){
    localStorage.setItem('shop_referrer', shop_referrer);
  }

  useEffect(() => {
    async function getShopList() {
      //if (user.data) {
        
        //console.log(user.data);
        const res = await Axios.get("/api/projects/list");
        console.log(res.data);
        setShopList(res.data.data.projects);
      //}
    }

    getShopList();
  }, []);

  useEffect(() => {
    //console.log(viewContext);
      setAdvanced(viewContext.isAdvanced);
  }, [viewContext]); 
  
  useEffect(() => {

    let serverURL = window.location.hostname; //(process.env.SERVER_URL !== undefined && process.env.SERVER_URL !== "") ? process.env.SERVER_URL : settings[process.env.NODE_ENV].server_url;
    if (serverURL.includes("localhost")) {
      serverURL = "http://" + serverURL + ":3000";
    } else {
      //serverURL = "https://" + serverURL;
      serverURL = "https://app.nftscribe.io";
    }
    
    let referURL = serverURL + "/shop";
    if (user.data) {
      setReferralURL(referURL + "?s=" + user.data.referral_code);
    } else {
      setReferralURL(null)
    }

  }, [user]);

  async function viewShopItem(project) {
    console.log(project);
    let navPath = `/shop/${project.slug}`;
    //console.log(navPath);
    navigate(navPath);
  }

  async function viewTokenStore() {
    let navPath = `/shop/token/play`;
    //console.log(navPath);
    navigate(navPath);
  }

  function truncateString(theString, truncLength = 50) {
    let retString = theString;
    if (theString.length > truncLength) {
      let strLen = theString.length;
      let string1 = theString.substring(0, truncLength);
      retString = string1 + "...";
    }
    return retString;
  }

  return (
    <Fragment>
      {user && user.data && 
      <DashboardNav user={user && user.data} center={!advanced} advanced={advanced}/>
      }
    <div className={advanced? 'max-w-full' : 'max-w-5xl content-center m-auto'}>
      
        
      <Card dark center>
        <Grid cols={referralURL ? "3" : "2"}>
          <div className='float-left'>
            <Button
              tiny
              key='centerButton'
              color={ props.destructive ? 'red' : 'green' }
              text={ "Back" }
              goto={'/'}
              //action={ () => navigate(-1) }
            />
          </div>
          {referralURL && 
            <div className='float-right text-center text-xs'>
              <Grid cols="2">
                <Button
                  tiny
                  key='centerButton2'
                  color={ props.destructive ? 'red' : 'blue' }
                  text={ "Affiliate Info" }
                  url={'https://wiki.goscribe.io/scribe/partners-and-affiliates/affiliate-program'}
                  target={'_blank'}
                />
                <Button
                  tiny
                  key='centerButton3'
                  color={ props.destructive ? 'red' : 'blue' }
                  text={ "Copy Affiliate Link" }
                  action={ () => navigator.clipboard.writeText(referralURL) }
                />
              </Grid>
            </div>
          }
          <div >
            <SocialShare 
                url={ referralURL }
                description={'The Scribe Shop has NFTs which boost your XP, make games more fun, and give you a blockchain identity!'}
              />
          </div>
        </Grid> 
      </Card>
      
      
        
      
    <Row>
      <Animate type="pop">
      
        {/*<Grid cols='4'>
      <Stat
          labelSmall        
          //loading={ stats?.loading }
          //value={ recentEvent ? recentEvent.event_description: '--'}
          //label={ 'Last Event: ' + (recentEvent ? new Date(recentEvent.date_created).toUTCString() : '--')}
          icon=''
        />
        <Stat
          labelSmall
          loading={ false }
          label={ 'Global Spaces XP Events (Last Hour)' }
          //value={ lastHourInfo? lastHourInfo.event_count.toLocaleString()  : '--'}
          icon=''
        />
        <Stat
          labelSmall
          loading={ false }
          label={ 'Global Spaces XP (Last Hour)' }
          //value={ lastHourInfo? isNaN(parseInt(lastHourInfo.event_xp)) ? 0 : parseInt(lastHourInfo.event_xp).toLocaleString()  : '--'}
          icon=''
        />
        <Stat
          labelSmall
          loading={ false }
          label={ 'Users Online (Last Hour)' }
         // value={ lastHourInfo? lastHourInfo.users_online.toLocaleString()  : '--'}          
          icon=''
        />
      </Grid>
  */}

        {shopList && shopList.length === 0 && (
          <Message
            text="Scribe is not currently tracking any Twitter spaces."
            type="info"
          />
        )}

        <Card>
          <strong>
            Choose an item to purchase/mint. Items which give you Bonus XP or Metaverse Credits are marked.
          </strong>
        </Card>

        {shopList && shopList.length > 0 && (
          <Grid cols="3">
            {1===3 && (
              <Card
                shadow
                rounded
                key={"credit_tokens"}
                id={"credit_tokens"}
                className={Style.mintCardMedium}
                center
              >
                <Image
                  className={Style.nftImage}
                  nftImage={true}
                  border={true}
                  source={
                    "https://nftscribe.s3.amazonaws.com/media/ScribeLogoStars_Credits.png"
                  }
                  interactive
                  action={(e) => {
                    viewTokenStore();
                  }}
                />
                <>
                  <div key="project_info">
                    <strong>{"Credits"}</strong>
                    <br />
                    <div className={"min-h-50"}>
                      <small className={Style.shopSummaryText}>
                        {"Inscribes, Upgrades, Apps, Games"}
                      </small>
                    </div>
                    <span>
                      <strong>Price: </strong>
                      {"Varies"}
                    </span>
                    <br />
                    <div className={"min-h-160"}>
                      <span>
                        {
                          "Get Credits you can use in Scribe and in Apps/Games"
                        }
                      </span>
                    </div>
                  </div>

                  <div key="space_button">
                    <Button
                      key="space"
                      className={Style.spaceButton}
                      color={"green"}
                      text="View"
                      action={(e) => {
                        viewTokenStore();
                      }}
                    />
                  </div>
                </>
              </Card>
            )}
                  

            {shopList.map((project, index) => {
              return (
                project.title !== null && (
                  <Card
                    shadow
                    rounded
                    key={project.id}
                    id={project.id}
                    className={project.chain === "poly" ? Style.mintCardMediumPoly : Style.mintCardMedium}
                    center
                  >
                    <Image
                      className={Style.nftImage}
                      nftImage={true}
                      border={true}
                      source={project.preview_image}
                      interactive
                      action={(e) => {
                        viewShopItem(project);
                      }}
                    />
                    <>
                      <div key="project_info">
                        <strong>{project.project_name}</strong>
                        <br />
                        <div className={"min-h-50"}>
                          <small className={Style.shopSummaryText}>
                            {project.shop_summary}
                          </small>
                        </div>
                        <span>
                          <strong>Mint price: </strong>
                          {project.mint_cost === "0"
                            ? "FREE"
                            : project.mint_cost + project.cost_label}
                        </span>
                        <br />
                        <div className={"min-h-160"}>
                          <span>
                            {truncateString(project.token_description, 200)}
                          </span>
                        </div>
                      </div>

                      <div key="space_button">
                        <Button
                          key="space"
                          className={Style.spaceButton}
                          color={"green"}
                          text="View"
                          action={(e) => {
                            viewShopItem(project);
                          }}
                        />
                      </div>
                    </>
                  </Card>
                )
              );
            })}
          </Grid>
        )}

        <Feedback />
      </Animate>
    </Row>
    </div>
    </Fragment>
  );
}
